import styled from 'styled-components';

export const ThumbnailsInformations = styled.div`
    padding: 0 25px;
    margin: 30px 0!important;
    display: grid;
    grid-gap: 16px;
    grid-area: 1/1;
    z-index: 1;
    color: var(--white);
    
    h2 {
        text-align: left;
    }
    
    h2, p {
        margin: 0;
        color: inherit;
    }
`;