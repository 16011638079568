import styled from 'styled-components';

export const StyledOl = styled.ol`
    list-style: none;  
    padding-left: unset;
    
    &:not(:first-child) > div {
        transform: translateY(-20%);
    }
`;
