import styled from 'styled-components';

export const BlogCategories = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 1rem;
    align-items: center;
    margin: 32px 0;
    color: var(--textNormal);
    opacity: ${({ hiddenForAnimation }) => hiddenForAnimation ? '0' : 'unset'};

    h3 {
        margin: 0;
    }
    
    ul {
        margin: 0;
        padding: 0;
    }
    
    ul li {
        margin: 0;
        display: inline-block;
        //padding: 6px 12px;
        
        a {
            text-decoration: none;
            color: var(--textNormal);
        }
    }  
`;
