import styled from 'styled-components';
import { Link } from "gatsby"
import ShadowOnHover from '../../css/ShadowOnHover';

export const ThumbnailsLink = styled(Link)`
    ${ShadowOnHover};
    display: grid;
        grid-template-columns: 1fr;
    text-decoration: none;
    background: var(--darkPurple);
    list-style: none;
    border-radius: 1rem;
    transition: box-shadow .3s ease-in, transform .3s ease-out;
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02),
                0 6.7px 5.3px rgba(0, 0, 0, 0.028),
                0 12.5px 10px rgba(0, 0, 0, 0.035);                        
    
    &:hover {
        transform: translateY(-5px);
    }
      
    h2 {
      color: var(--textNormal);
      margin-bottom: 0;
    }
    
    a {
        text-decoration: none;
    }
    
    .gatsby-image-wrapper {
        width: 100%;
        //max-width: 260px;
        grid-area: 1/1;
        filter: brightness(.3);
        border-radius: inherit;
      
        img {
            border-radius: 15px 0 0 15px;
        }
    }
    
    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    
        .gatsby-image-wrapper {
            max-width: unset;
            min-height: 230px;
            
            img {
                border-radius: 15px 15px 0 0;
            }
    }   
        
    }     

    
`;