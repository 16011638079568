import React, { useEffect } from "react"
import styled from 'styled-components';
import { useTranslation } from "react-i18next";
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Layout from "../layout/layout"
import Seo from "../components/seoComponent"
import Container from '../components/Container'
import { StyledOl, ThumbnailsStyledLi, ThumbnailsLink, ThumbnailsInformations, ThumbnailsExcerpt, ThumbnailsIconWrapper, } from "../components/PageBlog/index"
import DateIcon from '../../static/date.svg';
import TimeIcon from '../../static/time.svg';
import { websiteUrl } from "../data/navItems"
import formatDate from "../utils/formatDate"
// import SearchBar from "../components/Utils/SearchBar"

const StyledBlogPage = styled.section`
    padding: var(--firstSectionPadding);
    
    .blog_post {
        opacity: 0;
    }
    
    p.blog_desc {
        margin: 0 0 4rem;
    }
`

const EnglishWrapper = styled.div`
    min-height: calc(100vh - 472px);
    
    > p {
        margin-bottom: 3rem;
    }
`;

const BlogPage = ({ data, location }) => {

    const url = `${websiteUrl + location.pathname}`;
    const { t, i18n } = useTranslation()
    const locale = i18n.language;

    // const postCount = (data.totalPosts.totalCount / 2);
    // const initialList = [
    //   {
    //     id: 1,
    //   },
    //   {
    //     id: 2,
    //   },
    //   {
    //     id: 3,
    //   },
    //   {
    //     id: 4,
    //   },
    // ];
    //
    // const [setList] = React.useState(initialList);
    //
    // function handleToggleComplete(id) {
    //     const newList = data.pl.edges.map((item) => {
    //         const updatedItem = {
    //           ...item,
    //           isComplete: !item.isComplete,
    //         };
    //
    //         return updatedItem;
    //
    //     });
    //
    //     setList(newList);
    //   }
    //
    // const [searchTerm, setSearchTerm] = useState('');

    // useEffect(() => {
    //     gsap.set(['.blog_posts_categories'], { ease: 'power4.out', duration: 2.5 })
    //     gsap.fromTo('.blog_post', { x: 100, autoAlpha: 0 }, { x: 0, autoAlpha: 1, stagger: .2 })
    //     gsap.fromTo('.blog_posts_categories', { autoAlpha: 0 }, { autoAlpha: 1, duration: 1.5 })
    // }, [locale, data])

    useEffect(() => {
        gsap.set(['.blog_post'], { y: 50, autoAlpha: 0, ease: 'power4.out', duration: .5 })

        let startAndEnd = '-25% 85%';
        ScrollTrigger.batch(".blog_post", {
            trigger: '.blog_post',
            start: startAndEnd,
            end: startAndEnd,
            onEnter: batch => gsap.to(batch, { y: 0, autoAlpha: 1, stagger: 0.2 }),
            onEnterBack: batch => gsap.to(batch, { y: 50, autoAlpha: 0, stagger: 0.2 }),
        });
    }, [locale]);

    return (
        <Layout>
        <StyledBlogPage>
            <Seo
                title="Blog"
                description="O sprawach technicznych w nietechniczny sposób. Piszę o tym, jak działa internet oraz opisuję działanie nowoczesnych technologii"
                pageUrl={url}
                pageName={location.pathname.slice(1,-1)}
            />
            <Container>
                {/*<SectionHeader dark center>*/}
                {/*    <span className="underlined">Blog</span>*/}
                {/*    <span role="img" aria-label="emoji"> 🖊</span>*/}
                {/*</SectionHeader>*/}
                {/*<p>O sprawach technicznych, w nietechniczny sposób. Rozkładam internet na części pierwsze i opisuję działanie nowoczesnych narzędzi.</p>*/}
                {/*<p className="blog_desc">Jeżeli prowadzisz, lub planujesz zaczać prowadzić działalność w internecie, albo jesteś po prostu ciekawy - z pewnością znajdziesz tu coś dla siebie.</p>*/}

                {/*<SearchBar*/}
                {/*    searchTerm={searchTerm}*/}
                {/*    setSearchTerm={setSearchTerm}*/}
                {/*/>*/}
                {/*<p>Total posts: {postCount}</p>*/}
                {locale === 'pl' && <>
                    {/*<BlogCategories hiddenForAnimation className="blog_posts_categories">*/}
                    {/*    <h3>Kategorie:</h3>*/}
                    {/*    <div>*/}
                    {/*        <ul>*/}
                    {/*            <li>*/}
                    {/*                <Link to="/kategorie/podstawy">*/}
                    {/*                    <Tag>*/}
                    {/*                        #podstawy*/}
                    {/*                    </Tag>*/}
                    {/*                </Link>*/}
                    {/*            </li>*/}
                    {/*        </ul>*/}
                    {/*    </div>*/}
                    {/*</BlogCategories>*/}
                    <StyledOl className="blog_posts_list">
                        {/*{data.pl.edges.filter(posts => {*/}
                        {/*    if (searchTerm === '') {*/}
                        {/*        return posts*/}
                        {/*    } else if (posts.node.frontmatter.title.toLowerCase().includes(searchTerm.toLowerCase())) {*/}
                        {/*        return posts*/}
                        {/*    }*/}
                        {/*}).map(edge => {*/}
                        {data.pl.edges.map(edge => {
                            const image = getImage(edge.node.frontmatter.featuredImage);
                            return (
                                <ThumbnailsStyledLi className="blog_post" key={edge.node.id}>
                                    <ThumbnailsLink to={`/blog/${edge.node.fields.slug}` }>
                                        <GatsbyImage image={image} alt={`Artykuł: ${edge.node.frontmatter.title}`}/>
                                        <ThumbnailsInformations>
                                            <h2>{edge.node.frontmatter.title}</h2>
                                            <ThumbnailsExcerpt>
                                                {edge.node.excerpt}
                                            </ThumbnailsExcerpt>
                                            <ThumbnailsIconWrapper>
                                                <div>
                                                    <DateIcon/>
                                                    <span>{formatDate(edge.node.frontmatter.date, edge.node.frontmatter.lang)}</span>
                                                </div>
                                                <div>
                                                    <TimeIcon/>
                                                    <span>{edge.node.timeToRead} {t('utils.reading_time')}</span>
                                                </div>
                                            </ThumbnailsIconWrapper>
                                        </ThumbnailsInformations>
                                    </ThumbnailsLink>
                                </ThumbnailsStyledLi>
                            )
                        })}
                    </StyledOl>
                </>}
                {locale === 'en' &&
                    <>
                        {/*<BlogCategories>*/}
                        {/*    <h3>Categories</h3>*/}
                        {/*    <div>*/}
                        {/*        <ul>*/}
                        {/*            <li>*/}
                        {/*                <Link to="/kategorie/podstawy">*/}
                        {/*                    <StyledTag>*/}
                        {/*                        Basics*/}
                        {/*                    </StyledTag>*/}
                        {/*                </Link>*/}
                        {/*            </li>*/}
                        {/*        </ul>*/}
                        {/*    </div>*/}
                        {/*</BlogCategories>*/}
                        <StyledOl>
                            {/*{data.en.edges.filter(posts => {*/}
                            {/*    if (searchTerm === '') {*/}
                            {/*        return posts*/}
                            {/*    } else if (posts.node.frontmatter.title.toLowerCase().includes(searchTerm.toLowerCase())) {*/}
                            {/*        return posts*/}
                            {/*    } else {*/}
                            {/*        return posts*/}
                            {/*    }*/}
                            {/*}).map(edge => (*/}
                            {data.en.edges.map(edge => {
                                const image = getImage(edge.node.frontmatter.featuredImage);
                                return (
                                    <EnglishWrapper>
                                        <p>Currently I am not writing articles in english. I have wrote only one you can see below, because it is important to my activity.</p>
                                        <ThumbnailsStyledLi className="blog_post" key={edge.node.id}>
                                            <ThumbnailsLink to={`/blog/${edge.node.fields.slug}`}>
                                                <GatsbyImage image={image} alt={`Artykuł: ${edge.node.frontmatter.title}`}/>
                                                <ThumbnailsInformations>
                                                    <h2>{edge.node.frontmatter.title}</h2>
                                                    <ThumbnailsExcerpt>
                                                        {edge.node.excerpt}
                                                    </ThumbnailsExcerpt>
                                                    <ThumbnailsIconWrapper>
                                                        <div>
                                                            <DateIcon />
                                                            <span>{formatDate(edge.node.frontmatter.date, edge.node.frontmatter.lang)}</span>
                                                        </div>
                                                        <div>
                                                            <TimeIcon />
                                                            <span>{edge.node.timeToRead} {t('utils.reading_time')}</span>
                                                        </div>
                                                    </ThumbnailsIconWrapper>
                                                </ThumbnailsInformations>
                                            </ThumbnailsLink>
                                        </ThumbnailsStyledLi>
                                    </EnglishWrapper>
                                )
                            })}
                        </StyledOl>
                    </>
                }
            </Container>
        </StyledBlogPage>
        </Layout>
    )
};

export const query = graphql`
    query BlogPageQuery {
        pl: allMdx(sort: { fields: [frontmatter___date], order: DESC }, filter: {frontmatter: {lang: {eq: "pl"}}}) {
            edges {
                node {
                    timeToRead
                    excerpt(pruneLength: 200)
                    frontmatter {
                        date
                        title
                        lang
                        category
                        featuredImage {
                            childImageSharp {
                                gatsbyImageData(
                                    height: 250,
                                    quality: 100,
                                    placeholder: BLURRED,
                                    formats: [AUTO, WEBP, AVIF]
                                )
                            }
                        }
                    }
                    fields {
                        slug
                    }
                    id
                }
            }
        }
        en: allMdx(sort: { fields: [frontmatter___date], order: DESC }, filter: {frontmatter: {lang: {eq: "en"}}}) {
            edges {
                node {
                    frontmatter {
                        date
                        title
                        lang
                        category
                        featuredImage {
                            childImageSharp {
                                gatsbyImageData(
                                    height: 250,
                                    quality: 100,
                                    placeholder: BLURRED,
                                    formats: [AUTO, WEBP, AVIF]
                                )
                            }
                        }
                    }
                    fields {
                        slug
                    }
                    excerpt
                    id
                    timeToRead
                }
            }
        }
        latestPostImage: mdx(frontmatter: {latest: {eq: "yes"}}) {
            frontmatter {
                featuredImage {
                            childImageSharp {
                                fluid(maxWidth: 100, maxHeight: 300) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
            }
        }
        totalPosts: allMdx {
            totalCount
        }
        image: file(name: {eq: "form_start"}) {
            childImageSharp {
                resize(width: 1200) {
                    src
                    height
                    width
                }
            }
        }
    }
`


export default BlogPage