import styled from "styled-components"

export const ThumbnailsIconWrapper = styled.div`
    display: inline-flex;
    
    > div {
        display: flex;
        align-items: center;
    }

    svg {
        width: 22px;
        height: auto;
        margin-right: 10px;
        fill: var(--primary);
    }

    span {
        color: inherit;
        font-size: 80%;
    }

    span:first-of-type {
        margin-right: 20px;  
    }   
    
    @media (max-width: 350px) {
        display: unset;

        > div:first-child {
            margin-bottom: .25em;
        }
    }
`;