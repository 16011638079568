export default function formatDate(date, locale) {
    const dateArray = date.split('-');
    let year = Number(dateArray[0]);
    let month = Number(dateArray[1]);
    let day = Number(dateArray[2]);

    let options = { year: 'numeric', month: 'long', day: 'numeric' };
    let targetDay = new Date(year, month - 1, day);

    return targetDay.toLocaleDateString(locale, options);
}