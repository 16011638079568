import { css } from 'styled-components';

const ShadowOnHover = css`
    position: relative;
      
    &::after {
        position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        border-radius: inherit;
        box-shadow: 0 10px 40px -15px var(--primary);
        opacity: 0;
        transition: opacity .3s ease-in-out;
    }
    
    &:hover::after {
        opacity: 1;
    }
`;

export default ShadowOnHover;

